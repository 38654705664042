import Image from '@corratech/pylot-image'
import {
    ConfigurableProduct,
    ConfigurableVariant
} from '@pylot-data/pylotschema'
import React, { ReactElement, useCallback, useEffect } from 'react'
import { ProductPrice } from '../ProductPrice/ProductPrice'
import s from './FrequentlyBoughtTogether.module.scss'
import { ProductStockStatus } from '@pylot-data/enums/ProductStockStatus.d'
import { useProductUI } from '@pylot-data/hooks/product/use-product-ui'
import {
    SelectedOptions,
    VariantSelector
} from '@corratech/pylot-variant-selector'
import { ProductTileProps } from '../ProductTile/ProductTile'
import { OptionType } from '@corratech/pylot-swatches'
import cn from 'classnames'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

interface FrequentlyBoughtTogetherProductTileProps extends ProductTileProps {
    checked: boolean
    pdpSelectedOptions: SelectedOptions
    syncProduct: (
        productSku: string,
        selectedOptions?: Record<string, string>
    ) => void
    deselectProduct: (productSku: string) => void
    handleSelectProduct: (
        productSku: string,
        selectedOptions?: Record<string, string>
    ) => void
}

export const FrequentlyBoughtTogetherProductTile = ({
    product,
    checked,
    imageParam = {},
    className,
    syncProduct,
    deselectProduct,
    handleSelectProduct,
    pdpSelectedOptions
}: FrequentlyBoughtTogetherProductTileProps): ReactElement | null => {
    const isConfig = product.__typename === 'ConfigurableProduct'
    const {
        selectedOptions,
        setSelectedOptions,
        variant,
        options,
        isButtonDisabled,
        mediaGalleryEntries
    } = useProductUI(product)

    const { t } = useTranslation(['common', 'pdp'])

    const fullImageUrl = useCallback(
        (imageUrl?: string): string => {
            const widthString = imageParam.width
                ? `&width=${imageParam.width}`
                : ''
            return `${imageUrl}?auto=webp&format=pjpg${widthString}`
        },
        [imageParam.width]
    )

    const productPriceRange = isConfig
        ? variant.price_range
        : product.price_range

    useEffect(() => {
        if (product.currentItem) {
            setSelectedOptions(pdpSelectedOptions)
        }
    }, [pdpSelectedOptions, product.currentItem, setSelectedOptions])

    useEffect(() => {
        if (!isButtonDisabled) {
            syncProduct(product.sku, selectedOptions as Record<string, string>)
        } else {
            deselectProduct(product.sku)
        }
    }, [variant, isButtonDisabled, selectedOptions])

    const onChange = () => {
        if (!isButtonDisabled) {
            handleSelectProduct(
                product.sku,
                selectedOptions as Record<string, string>
            )
        }
    }

    return (
        <div className={cn(s['productitem-block'], className)}>
            <label
                className={cn(s['root-checkbox'], {
                    [s['active']]: checked && !isButtonDisabled,
                    [s['disabled']]: isButtonDisabled
                })}
                htmlFor={`product_checkbox_${product.sku}`}
            >
                <input
                    type="checkbox"
                    name={`product_checkbox_${product.sku}`}
                    className={s['input-checkbox']}
                    title={
                        isButtonDisabled
                            ? t('Variation currently unavailable')
                            : ''
                    }
                    onChange={onChange}
                    checked={checked && !isButtonDisabled}
                />
            </label>
            <Link
                href={`/product/${product.url_key}`}
                className="cursor-pointer"
            >
                <div
                    className={s['productimage-block']}
                    style={{
                        width: 150
                    }}
                >
                    <Image
                        src={fullImageUrl(mediaGalleryEntries[0]?.url!)}
                        alt={product.name ? product.name : 'placeholder'}
                        style={{ objectFit: 'contain' }}
                        width={320}
                        height={320}
                        className={s['product-img']}
                    />
                </div>
            </Link>
            <div className={s['product-details']}>
                <div className={s['product-name-line']}>
                    <h2 className={s['product-name']}>
                        <span className={s['productname-link']}>
                            {product.name}
                        </span>
                    </h2>

                    <ProductPrice
                        className={String(product.__typename).toLowerCase()}
                        priceRange={productPriceRange}
                    />
                </div>
                {product.currentItem && (
                    <span>({product.currentItem ? t('This item') : ''})</span>
                )}
                {product.product_type_label && (
                    <div className={s['product-type']}>
                        {product.product_type_label}
                    </div>
                )}
                {product.model_no && (
                    <div className={s['model-no']}>{product.model_no}</div>
                )}

                {isConfig && product.currentItem && (
                    <div className="mt-2">
                        {Object.entries(selectedOptions).map(([key, value]) => (
                            <div key={key} className="mt-1">
                                {key.toLocaleUpperCase()}
                                <span className="font-bold">
                                    : {value?.toLocaleUpperCase()}
                                </span>
                            </div>
                        ))}
                    </div>
                )}

                {isConfig &&
                    product.configurable_options &&
                    !product.currentItem && (
                        <VariantSelector
                            disableAll={
                                (product as ConfigurableProduct)
                                    .stock_status ===
                                ProductStockStatus.OutOfStock
                            }
                            variants={product.variants as ConfigurableVariant[]}
                            options={options}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            isPDP
                            excludedOptions={[]}
                            type={OptionType.Dropdown}
                        />
                    )}
            </div>
        </div>
    )
}
