import cn from 'classnames'
interface FeatureBarProps {
    className?: string
    title: string
    description?: string
    hide?: boolean
    action?: React.ReactNode
    onClick?: () => void
    variant?: string
}

const FeatureBar: React.FC<FeatureBarProps> = ({
    title,
    description,
    className,
    action,
    hide,
    onClick,
    variant
}) => {
    const rootClassName = cn(
        variant === 'blackVariant'
            ? 'z-50 text-center md:text-left p-3 bg-secondary text-sm md:flex flex-row justify-center items-center font-medium fixed bottom-0 w-full'
            : 'text-center md:text-left p-6 bg-primary text-sm md:flex flex-row justify-center items-center font-medium fixed bottom-0 w-full',
        {
            'translate-y-0 opacity-100 z-30': !hide,
            'translate-y-full opacity-0 -z-1': hide
        },
        className
    )
    return (
        // eslint-disable-next-line
        <div onClick={onClick} className={rootClassName}>
            <span
                className={cn('block md:inline', {
                    'text-white font-bold text-lg': variant === 'blackVariant'
                })}
            >
                {title}
            </span>
            <span className="block mb-6 md:inline md:mb-0 md:ml-2">
                {description}
            </span>
            {action && action}
        </div>
    )
}

export default FeatureBar
