import { Button } from '@corratech/pylot-ui'
import { useTranslation } from 'next-i18next'
import { Dispatch, SetStateAction } from 'react'
import { BundleItem, BundleItemOption } from '@pylot-data/pylotschema'
import cn from 'classnames'
import s from '@pagestyles/PDP.module.scss'
import { QuantitySelector } from '@components/common/QuantitySelector'

export interface MappedBundleItemOption extends BundleItemOption {
    isSelected?: boolean
}

export interface MappedBundleItem extends BundleItem {
    options?: MappedBundleItemOption[]
}

export interface MappedBundleItems {
    [x: string]: MappedBundleItem
}

export interface BundleProductProps {
    quantity: number
    setQuantity: Dispatch<SetStateAction<number>>
    buttonLabel: string
    isAddToCartLoading?: boolean
    isAddBundleToCartDisabled: boolean
    handleAddBundleToCart: () => Promise<void>
}

const BundleProduct = ({
    quantity,
    setQuantity,
    buttonLabel,
    isAddToCartLoading = false,
    isAddBundleToCartDisabled,
    handleAddBundleToCart
}: BundleProductProps): JSX.Element => {
    const { t } = useTranslation(['common', 'pdp'])

    return (
        <div className={s['qty-button-wrapper']}>
            <div className={cn(s['qty-selector'])}>
                <label htmlFor="input-quantity">{t('QTY:')}</label>

                <QuantitySelector
                    quantity={quantity}
                    setQuantity={setQuantity}
                />
            </div>
            <div className={s['add-to-cart-wrapper']}>
                <Button
                    aria-label={buttonLabel}
                    type="button"
                    className={s['add-to-cart-button']}
                    onClick={handleAddBundleToCart}
                    loading={isAddToCartLoading}
                    disabled={isAddBundleToCartDisabled}
                >
                    {buttonLabel}
                </Button>
            </div>
        </div>
    )
}

export default BundleProduct

export const getBundleSelectedOptionsTotalPrice = (
    mappedBundleItemsEntries: [string, MappedBundleItem][]
): number => {
    let bundleTotalPrice = 0
    for (const [, item] of mappedBundleItemsEntries) {
        for (const option of item?.options || []) {
            if (!option.isSelected) continue

            bundleTotalPrice =
                (option.product?.price_range.minimum_price?.final_price.value ||
                    0) *
                    (option?.quantity || 1) +
                bundleTotalPrice
        }
    }

    return bundleTotalPrice
}

export const getIsEveryRequiredBundleItemSelected = (
    mappedBundleItemsEntries: [string, MappedBundleItem][]
): boolean => {
    for (const [, item] of mappedBundleItemsEntries) {
        if (
            item.required &&
            !item.options?.some((option) => option.isSelected)
        ) {
            return false
        }
    }
    return true
}
