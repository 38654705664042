import { useEffect, FC } from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { useStoreConfig } from '@pylot/config'

declare global {
    interface Window {
        bgpOpt?: null | {
            callback: (data: any) => void
            live: boolean
        }
        populatePricesTable?: null | ((data: any) => void)
    }
}

type StringStringObject = { [key: string]: string }

const SCRIPT_STRATEGY = 'afterInteractive'

const LivePrices: FC = () => {
    const router = useRouter()
    const {
        base: { live_price }
    } = useStoreConfig()
    const isLiveGoldPricesPage = live_price?.enabled_urls?.some((urlToCheck) =>
        router?.asPath?.includes(urlToCheck)
    )

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.bgpOpt = { callback: getSpots, live: true }
            if (isLiveGoldPricesPage) {
                window.populatePricesTable = populatePricesTable
            }
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.bgpOpt = null
                window.populatePricesTable = null
            }
        }
    }, [router.asPath])

    const getSpots = (data: any) => {
        const _metals = ['Gold', 'Silver', 'Platinum', 'Palladium']
        const _classByMetal: StringStringObject = {
            gold: 'au',
            silver: 'ag',
            platinum: 'pt',
            palladium: 'pd'
        }

        _metals.forEach((item) => {
            const _id = item.toLowerCase()
            let _html = ''
            _html += item + ' $' + data[`${_id}Ask`].toFixed(2)
            _html +=
                ' <i class="caret-' +
                (data[`${_id}Change`] > 0 ? 'up' : 'down') +
                '" aria-hidden="true"></i> '
            _html += '<span>$' + data[`${_id}Change`].toFixed(2) + '</span>'

            if (_id) {
                const elements = document.querySelectorAll(
                    `.price-${_classByMetal[_id]}`
                )
                elements.forEach((element) => {
                    element.innerHTML = _html
                })
            }
        })

        const populatePricesTableCallback = window?.populatePricesTable

        if (typeof populatePricesTableCallback === 'function') {
            populatePricesTableCallback(data)
        }
    }

    const updateElement = (
        selector: string,
        value: string,
        metalElement: any
    ) => {
        const element = metalElement.querySelector(selector)
        if (element) element.innerHTML = value
    }

    const updateClass = (
        selector: string,
        add: string,
        remove: string,
        metalElement: any
    ) => {
        const element = metalElement.querySelector(selector)
        if (element) {
            element.classList.remove(remove)
            element.classList.add(add)
        }
    }

    const updateTable = (metal: string, data: any) => {
        const metalElement = document.querySelector(`.${metal.toLowerCase()}`)
        if (!metalElement) return

        const askValueIndex = `${metal.toLowerCase()}Ask`
        const bidValueIndex = `${metal.toLowerCase()}Bid`
        const changeValueIndex = `${metal.toLowerCase()}Change`

        const askValue = `$${data[askValueIndex]?.toFixed(2)}`
        const bidValue = `$${data[bidValueIndex]?.toFixed(2)}`
        const changeValue = `$${data[changeValueIndex]?.toFixed(2)}`

        updateElement('.ask', askValue, metalElement)
        updateElement('.bid', bidValue, metalElement)
        updateElement('.price-change', changeValue, metalElement)

        const isChangePositive = data[changeValueIndex] > 0

        updateClass(
            '.arrow',
            isChangePositive ? 'fa-caret-up' : 'fa-caret-down',
            isChangePositive ? 'fa-caret-down' : 'fa-caret-up',
            metalElement
        )
        updateClass(
            '.price-change',
            isChangePositive ? 'price-up' : 'price-down',
            isChangePositive ? 'price-down' : 'price-up',
            metalElement
        )
    }

    const populatePricesTable = (data: any) => {
        ;['Gold', 'Silver', 'Platinum', 'Palladium'].forEach((metal) => {
            updateTable(metal, data)
        })

        const updateTimestamp = document.querySelector(
            '.content-block-price-update.update'
        )
        if (updateTimestamp) {
            updateTimestamp.innerHTML = `${data.timestamp} CT`
        }

        if (document?.querySelector('.price-change')) {
            const flash = (item: HTMLElement) => {
                let opacity = 100
                const color = '255, 255, 20'
                const interval = setInterval(() => {
                    opacity -= 3
                    if (opacity <= 0) clearInterval(interval)
                    item.style.background = `rgba(${color}, ${opacity / 100})`
                }, 20)
            }

            flash(document.querySelector('.price-change') as HTMLElement)
        }
    }

    return (
        <Script
            async
            src={live_price?.live_price_script}
            strategy={SCRIPT_STRATEGY}
        />
    )
}

export default LivePrices
