export const productSearchSuggestionQuery = /* GraphQL */ `
    query categories(
        $search: String
        $pageSize: Int = 4
        $currentPage: Int = 0
    ) {
        products(
            search: $search
            pageSize: $pageSize
            currentPage: $currentPage
        ) {
            total_count
            items {
                __typename
                name
                pricing_table
                price_range {
                    __typename
                    minimum_price {
                        __typename
                        regular_price {
                            __typename
                            value
                            currency
                        }
                        final_price {
                            __typename
                            value
                            currency
                        }
                        discount {
                            __typename
                            amount_off
                        }
                    }
                }

                small_image {
                    __typename
                    url
                }
                url_key
            }
        }
    }
`
