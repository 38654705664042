import { FC, useEffect, useState, useCallback, useRef } from 'react'
import cn from 'classnames'
import s from './Searchbar.module.scss'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import SearchAutocomplete from '../SearchAutocomplete'
import debounce from 'lodash.debounce'
import { Search } from '@components/icons'
import { Button } from '@corratech/pylot-ui'
import { useUI } from '@corratech/pylot-ui/context'
import { validateKeyEvent } from '@corratech/pylot-utils'

const SEARCH_PAGE_URL = `/search`
interface Props {
    className?: string
    id?: string
}

const Searchbar: FC<Props> = ({ className, id = 'search' }) => {
    const router = useRouter()
    const { t } = useTranslation('common')
    const [searchTerm, setSearchTerm] = useState<string>('')
    const { closeSearch } = useUI()
    const [debouncedSearchTerm, _setDebouncedSearchTerm] = useState<string>('')

    const escFunction = useCallback((event) => {
        if (validateKeyEvent(event, ['Escape'], [27])) {
            closeSearch()
        }
    }, [])

    useEffect(() => {
        router.prefetch(SEARCH_PAGE_URL)

        router.events.on('routeChangeStart', closeSearch)
        document.addEventListener('keydown', escFunction, false)

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeStart', closeSearch)
            document.removeEventListener('keydown', escFunction, false)
        }
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setDebouncedSearchTerm = useCallback(
        debounce((value) => {
            _setDebouncedSearchTerm(value)
        }, 500),
        []
    )

    const ref = useRef() as React.MutableRefObject<HTMLInputElement>

    useEffect(() => {
        if (ref.current) {
            ref.current.focus()
        }
    }, [])

    useEffect(() => {
        setDebouncedSearchTerm(searchTerm)
    }, [searchTerm, setDebouncedSearchTerm])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        router.push({
            pathname: SEARCH_PAGE_URL,
            query: searchTerm ? { q: searchTerm.trim() } : {}
        })
    }
    return (
        <form
            className={cn(s['searchbar-main'], className)}
            onSubmit={handleSubmit}
        >
            <label className="sr-only" htmlFor={id} id="search-label">
                {t('Search')}
            </label>
            <input
                type="text"
                aria-autocomplete="list"
                aria-controls="search-results"
                aria-haspopup="listbox"
                aria-describedby="search-instructions"
                id={id}
                className={s.input}
                ref={ref}
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                placeholder={t('What can we help you find?')}
                defaultValue={router.query.q}
                autoComplete="off"
            />
            <div className={s['icon-container']}>
                <Button
                    variant="link"
                    disabled={searchTerm.length <= 2}
                    type="submit"
                    aria-label={t('Search')}
                >
                    <span aria-hidden="true">
                        <Search />
                    </span>
                </Button>
            </div>
            {debouncedSearchTerm.length > 2 && (
                <SearchAutocomplete
                    searchTerm={debouncedSearchTerm}
                    searchPageURL={SEARCH_PAGE_URL}
                />
            )}
        </form>
    )
}

export default Searchbar
