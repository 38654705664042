import { useTranslation } from 'next-i18next'
import { useCallback, useMemo, useReducer } from 'react'

import s from './FrequentlyBoughtTogether.module.scss'
import { FrequentlyBoughtTogetherProductTile } from './FrequentlyBoughtTogetherProductTile'
import { Button } from '@corratech/pylot-ui'
import {
    CartItemInput,
    ConfigurableProduct,
    ProductInterface
} from '@pylot-data/fwrdschema'
import { useAddToCart } from '@corratech/pylot-cart-manager'
import { ToastType, useUI } from '@corratech/pylot-ui/context'
import { ConfigurableProductOptionsValues } from '@pylot-data/pylotschema'
import { formatPrice } from '@corratech/pylot-price'
import { useRouter } from 'next/router'
import { SelectedOptions } from '@corratech/pylot-variant-selector'
interface FrequentlyBoughtTogetherProps {
    product: ProductInterface
    pdpSelectedOptions: SelectedOptions
}

type FrequentlyBoughtTogetherProduct = ProductInterface &
    ConfigurableProduct & {
        currentItem?: boolean
        options?: ConfigurableProductOptionsValues[]
    }

type SelectedProductType = {
    sku: string
    options?: Record<string, string>
}

type SelectedProductsType = SelectedProductType[] | []

type SelectedProductActionType = {
    type: 'ADD' | 'REMOVE' | 'TOGGLE'
    payload: SelectedProductType
}

export default function FrequentlyBoughtTogether({
    product,
    pdpSelectedOptions
}: FrequentlyBoughtTogetherProps): JSX.Element | null {
    const { t } = useTranslation(['common', 'pdp'])

    const productsToRender: FrequentlyBoughtTogetherProduct[] = useMemo(
        () => [
            { ...product, currentItem: true },
            ...(product.related_products as FrequentlyBoughtTogetherProduct[])
        ],
        [product]
    )

    const [selectedProducts, dispatchProduct] = useReducer(
        (
            currProducts: SelectedProductsType,
            action: SelectedProductActionType
        ) => {
            const { type, payload } = action

            const existingProduct = currProducts.find(
                ({ sku }) => sku === payload.sku
            )

            switch (type) {
                case 'ADD': {
                    if (existingProduct) {
                        return currProducts.map((product) =>
                            product.sku === existingProduct.sku
                                ? {
                                      ...product,
                                      ...payload
                                  }
                                : product
                        )
                    }

                    return [...currProducts, payload]
                }
                case 'REMOVE': {
                    if (existingProduct) {
                        return currProducts.filter(
                            ({ sku }) => sku === payload.sku
                        )
                    }

                    return currProducts
                }
                case 'TOGGLE': {
                    if (existingProduct) {
                        return currProducts.filter(
                            ({ sku }) => sku !== payload.sku
                        )
                    }

                    return [...currProducts, payload]
                }

                default:
                    return currProducts
            }
        },
        []
    )

    const selectedVariants = productsToRender
        .filter(({ sku }) =>
            selectedProducts.find(
                (selectedProduct) => selectedProduct.sku === sku
            )
        )
        .map((item) => {
            const { variants, sku, __typename } = item

            if ((__typename as string) === 'SimpleProduct') {
                return item
            }

            const selectedOptions = selectedProducts.find(
                (selectedProduct) => selectedProduct.sku === sku
            )

            return (
                selectedOptions &&
                variants?.find((variant) =>
                    variant?.attributes?.every(
                        (attr) =>
                            selectedOptions.options![
                                attr?.code as keyof SelectedOptions
                            ] === attr?.label
                    )
                )?.product
            )
        })

    const { addToCart, isAdding } = useAddToCart()
    const { openSidebar, openCartToast } = useUI()
    const { locale = 'en' } = useRouter()

    const getProductOptions = () => {
        const items = productsToRender
            .filter(({ sku }) =>
                selectedProducts.find(
                    (selectedProduct) => selectedProduct.sku === sku
                )
            )
            .map((product) => {
                const selectedOption = selectedProducts.find(
                    (selectedProduct) => selectedProduct.sku === product.sku
                )?.options

                if (selectedOption) {
                    const options = Object.keys(selectedOption).map((field) => {
                        const option = (
                            product as ConfigurableProduct
                        )?.configurable_options?.find(
                            (option) => option?.attribute_code === field
                        )

                        if (option) {
                            const optionvValue = option.values?.find(
                                (opt) => opt?.label === selectedOption[field]
                            )

                            if (optionvValue) {
                                return optionvValue
                            }
                        }
                    })

                    return {
                        ...product,
                        options: options
                    }
                }

                return product
            })

        return items as FrequentlyBoughtTogetherProduct[]
    }

    const handleAddToCart = async () => {
        const itemOptions =
            getProductOptions() as FrequentlyBoughtTogetherProduct[]

        const response = await addToCart(
            itemOptions.map((item: FrequentlyBoughtTogetherProduct) => ({
                sku: item.sku,
                quantity: 1,
                selected_options: item?.options?.map(
                    (option: ConfigurableProductOptionsValues) => option?.uid
                )
            })) as CartItemInput[],
            [],
            [],
            true,
            true
        )
        const errorMessage = response?.errors?.[0]?.message ?? null
        const successMessage = t('cart|items was added to cart')
        openSidebar()
        openCartToast(
            errorMessage ? errorMessage : successMessage,
            errorMessage ? ToastType.Warning : ToastType.Success
        )
    }

    const price = useMemo(() => {
        const currency =
            product.price_range?.minimum_price?.final_price?.currency

        if (selectedVariants.length) {
            const selectedTotal = selectedVariants.reduce((acc, current) => {
                return (
                    acc +
                    Number(
                        current?.price_range?.minimum_price.final_price.value ??
                            0
                    )
                )
            }, 0)

            const finalPrice = formatPrice(
                { value: selectedTotal, currency },
                locale
            )

            return finalPrice
        }

        return formatPrice({ value: 0, currency }, locale)
    }, [product, selectedVariants, locale])

    const handleSelectProduct = useCallback(
        (productSku: string, selectedOptions?: Record<string, string>) => {
            dispatchProduct({
                type: 'TOGGLE',
                payload: {
                    sku: productSku,
                    options: selectedOptions
                }
            })
        },
        [dispatchProduct]
    )

    const syncProduct = useCallback(
        (productSku: string, selectedOptions?: Record<string, string>) => {
            dispatchProduct({
                type: 'ADD',
                payload: {
                    sku: productSku,
                    options: selectedOptions
                }
            })
        },
        [dispatchProduct]
    )

    const deselectProduct = useCallback(
        (productSku: string) => {
            dispatchProduct({
                type: 'REMOVE',
                payload: {
                    sku: productSku
                }
            })
        },
        [dispatchProduct]
    )

    return (
        <section className={s['frequently-bought-together--wrapper']}>
            <h2 className={s['frequently-bought-together--title']}>
                {t('Frequently Bought Together')}
            </h2>
            <div
                className={s['frequently-bought-together-related--items']}
                id="frequently-bought-together-related--items"
            >
                {productsToRender.map((product) => (
                    <FrequentlyBoughtTogetherProductTile
                        product={product}
                        pdpSelectedOptions={pdpSelectedOptions}
                        key={product!.sku}
                        isPDP
                        imageParam={{ width: 150 }}
                        checked={
                            !!selectedProducts.find(
                                ({ sku }) => sku === product!.sku!
                            )
                        }
                        syncProduct={syncProduct}
                        deselectProduct={deselectProduct}
                        handleSelectProduct={handleSelectProduct}
                    />
                ))}
            </div>
            <div className={s['frequently-bought-together--price']}>
                <span>{t('Total Price')}:</span>
                <b>{price}</b>
            </div>
            <div className={s['frequently-bought-together--btn']}>
                <Button
                    loading={isAdding}
                    disabled={selectedProducts.length === 0}
                    onClick={handleAddToCart}
                >
                    {t('Add to cart')}
                </Button>
            </div>
        </section>
    )
}
