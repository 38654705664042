import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchSuggestion } from '@pylot-data/hooks/search/use-search-suggestion'
import s from './SearchAutocomplete.module.scss'
import ProductItemTiny from './ProductItemTiny'

import Link from 'next/link'
import { LoadingDots } from '@corratech/pylot-ui'
import { useRouter } from 'next/router'
type autoCompleteType = {
    searchTerm: string
    searchPageURL: string
}
export const PRODUCT_COUNT_IN_DROPDOWN = 6

const SEARCH_PAGE_URL = `/search`

const SearchAutocomplete: FC<autoCompleteType> = ({
    searchTerm,
    searchPageURL
}) => {
    const ref = useRef(null)
    const router = useRouter()
    const [maxHeight, setMaxHeight] = useState('auto')

    useEffect(() => {
        const updateMaxHeight = () => {
            if (ref.current) {
                //@ts-ignore
                const rect = ref?.current?.getBoundingClientRect()
                const maxComponentHeight = window.innerHeight - rect.top
                setMaxHeight(`${maxComponentHeight - 10}px`)
            }
        }

        // Call once and set up event listener for window resizing
        updateMaxHeight()
        window.addEventListener('resize', updateMaxHeight)

        // Clean up listener
        return () => window.removeEventListener('resize', updateMaxHeight)
    }, [])

    const { t } = useTranslation()
    const { products, isValidating } = useSearchSuggestion(searchTerm, {
        revalidateOnFocus: false
    })
    useEffect(() => {
        if (searchTerm?.length > 2) {
            router.prefetch(
                `${SEARCH_PAGE_URL}?q=${searchTerm}`,
                `${SEARCH_PAGE_URL}?q=${searchTerm}`
            )
        }
    }, [products, router, searchTerm])

    if (isValidating) {
        return (
            <div className={s['search-container']}>
                <LoadingDots />
            </div>
        )
    }

    if (products?.items && products.items.length > 0) {
        return (
            <ul
                className={s.autocomplete}
                id="search-results"
                role="listbox"
                aria-labelledby="search-label"
                style={{ maxHeight: maxHeight }}
                ref={ref}
            >
                <li className={s['autocomplete-list']} role="group">
                    <div id="search-instructions" className={s['search-info']}>
                        {t(
                            'Begin typing to search, use arrow keys to navigate, Enter to select'
                        )}
                    </div>

                    <Link
                        href={`${searchPageURL}?q=${searchTerm}`}
                        // eslint-disable-next-line i18next/no-literal-string
                        aria-live="assertive"
                        className={s['autocomplete-heading']}
                    >
                        <span role="heading" aria-level={2}>
                            {Number(products.total_count) <
                            PRODUCT_COUNT_IN_DROPDOWN
                                ? t('Products {{ count }}', {
                                      count: Number(products.total_count)
                                  })
                                : t('Showing 5 Products of {{ count }}', {
                                      count: Number(products.total_count)
                                  })}
                        </span>
                    </Link>

                    <ul role="listbox">
                        {products.items
                            .slice(0, Math.min(5, products.items.length))
                            .map((product, index) => (
                                <ProductItemTiny
                                    product={product}
                                    key={index}
                                />
                            ))}
                    </ul>
                </li>

                <li className={s['all-products-li']}>
                    <Link
                        href={`${searchPageURL}?q=${searchTerm}`}
                        className="text-link"
                    >
                        {t('View all search results')}
                    </Link>
                </li>
            </ul>
        )
    } else {
        return (
            <div className={s['search-container']}>
                {t('No Suggestions Found')}
            </div>
        )
    }
}

export default SearchAutocomplete
