import React, { ReactElement } from 'react'
import { Maybe } from '@pylot-data/fwrdschema'
import { ProductPrice } from '@components/common/ProductPrice/ProductPrice'
import Link from 'next/link'
import Image from '@corratech/pylot-image'
import s from './SearchAutocomplete.module.scss'
import { ProductInterface } from '@pylot-data/pylotschema'
import { buildUrl } from '@pylot-data/routing/urlBuilder'

export type ProductItemProp = {
    product?: Maybe<ProductInterface>
}
const ProductItemTiny = ({ product }: ProductItemProp): ReactElement | null => {
    if (!product) return null

    const productUrl = buildUrl({
        model: product
    })

    return (
        <li
            className={s['autocomplete-product-tiny']}
            role="option"
            aria-selected="false"
        >
            <Link href={`${productUrl}`}>
                <Image
                    src={product.small_image?.url!}
                    width={100}
                    height={100}
                    alt={product?.name ?? 'placeholder'}
                />
            </Link>
            <div className={s['autocomplete-product-tiny-name']}>
                <div>
                    <Link href={`${productUrl}`}>{product.name}</Link>
                </div>
                <ProductPrice priceRange={product?.price_range} />
            </div>
        </li>
    )
}
export default ProductItemTiny
